<template>
    <div class="service">
        <el-text class="title">我们能做的？</el-text>
        <el-row>
            <el-col :span="isMobile?24:12">
                <div class="row">
                    <el-icon size="40" color="white"><Memo/></el-icon>
                    <div class="col">
                        <h2 class="content-title">
                            极简的设计风格
                        </h2>
                        <p>
                            大道至简，优秀的设计应同时具备视觉上的美观性以及信息传播的高效性。而极简主义的设计理念能够很好地将设计的美观性和高效性融合在一起。
                        </p>
                    </div>
                </div>
            </el-col>

            <el-col :span="isMobile?24:12">
                <div class="row">
                    <el-icon size="40" color="white"><Sunny/></el-icon>
                    <div class="col">
                        <h2 class="content-title">
                            极致的用户体验 
                        </h2>
                        <p>
                            超越预期，就是不断减少用户预期之内的“分内事”，把原本用户“应该做的”，变成你产品或服务“应该做的”。很多问题没有被解决并不是没有解决方案，而是大多数人根本没有意识到那是问题。
                        </p>
                    </div>
                </div>
            </el-col>


            <el-col :span="isMobile?24:12">
                <div class="row">
                    <el-icon size="40" color="white"><Apple/></el-icon> 
                    <div class="col">
                        <h2 class="content-title">
                            保持工匠的精神
                        </h2>
                        <p>
                            
保持工匠的精神一辈子，总是还得让一些善意执念推着往前，我们因此能愿意去听从内心的安排，专注做点东西。至少对得起光阴岁月，其他的就留给时间去说吧。
                        </p>
                    </div>
                </div>
            </el-col>

            <el-col :span="isMobile?24:12">
                <div class="row">
                    <el-icon size="40" color="white"><MagicStick/></el-icon>
                    <div class="col">
                        <h2 class="content-title">
                            一切皆有可能
                        </h2>
                        <p>
                            万物之中，希望至美。永远怀揣希望，是我们坚持下去的动力，我们也希望志同道合的伙伴加入我们，一起创造激动人心的好产品。
                        </p>
                    </div>
                </div>
            </el-col>


        </el-row>
    </div>
</template>

<script>
import {getCurrentInstance,computed} from 'vue';
export default {
    name: 'Service',
    setup() {
        let {proxy} = getCurrentInstance();
        console.log('是否是手机端：',proxy.$isMobile());
        let isMobile= computed(()=>proxy.$isMobile());
        return {
            isMobile
        };
    }
}
</script>

<style lang="scss" scoped>
.service {
    background-color: #333333;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    font-size: 35px;
    color: white;
    margin-top: 60px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding-top: 20px;
    padding-bottom: 20px;
}

.row{
    display: flex;
    flex-direction: row;
    padding-left: 40px;
}
.col{
    display: flex;
    flex-direction: column;
    margin-left: 60px;
}

.el-row{
    margin-top: 80px;
    padding-left: 20%;
    padding-right: 20%;
}

.content-title{
    text-align: start;
    color: #1a4a4e;
    font-size: 20px;
    margin-bottom: 10px;

}
.col > p{
    color: white;
    font-weight: 150;

    margin-bottom: 60px;
}


</style>