import { createStore } from 'vuex'

export default createStore({
  state: {
    featurelist: [
      {
        image:'feature/bianqian.jpg',
        title:'精确提醒',
        content:'此刻清单采用系统通知和微信推送的方式将对您需要提醒的事件进行提醒，从而保证您能精确收到重要事项的提醒，避免遗漏与错过。'
      },
      {
        image:'feature/rili.jpg',
        title:'多种日历视图',
        content:'在月视图中概览全局，一眼就能看出近一个月的事件安排，提前规划；在日视图，周视图中查看时间轴，即刻掌握各天的日程。'
      },
      {
        image:'feature/wenjianjia.jpg',
        title:'帮助分类管理您的任务',
        content:'当你记录的事情越来越多，那么合理的组织就尤为重要了。此刻清单提供了任务分类的功能，你可以根据任务的分类进行整理，将任务进行条理化，避免焦头烂额。'
      },
      {
        image:'feature/todo.jpg',
        title:'随时随地，想记就记',
        content:'此刻清单提供了快速记录的入口，突然的想法与念头也能保证您能快速地记录下来，并且此刻清单支持离线，游客两种模式，不用登录，没有网络也能保证正常使用。'
      },
      {
        image:'feature/focus.jpg',
        title:'番茄工作法，保持专注',
        content:'使用番茄工作法,在“25分钟工作、5分钟休息”的节奏中,集中精力搞定一件事,不受外界因素打扰,效率翻倍。'
      },
      {
        image:'feature/time.jpg',
        title:'合理规划您的时间',
        content:'此刻清单以清晰简洁的方式帮你规划您的任务安排，并且通过四象限工作法的方式，根据紧急度与重要度两个维度智能排序，免去遗忘重要事件的困扰。'
      }
    ],
    screenshootlist:[
      "screenshoot/1.png",
      "screenshoot/2.png",  
      "screenshoot/3.png",
      "screenshoot/4.png",
      "screenshoot/5.png"
    ],

  },
  getters: {
  },
  mutations: {
  
  },
  actions: {
  },
  modules: {
  }
})
