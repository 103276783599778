<template>
    <div class="home">
        <el-text size="large" class="title_text" tag="b">此刻清单</el-text>
        <el-text class="text_dec">您的私人时间助手</el-text>
        <el-button  type="primary" class="download" @click="download">
            立即下载<el-icon class="el-icon--right">
                <el-icon><Download /></el-icon>
            </el-icon>
        </el-button>




    </div>
</template>

<script>
import _service from "@/service/index.js";
export default {
    name: 'CKMain',
    methods: {
        download() {
            _service.getVersion().then(res => {
                console.log(JSON.stringify(res));
                window.open(res.apkUrl);
            })
        }
    },
    setup() {
        return {
        };
    }
}
</script>

<style lang="scss" scoped>
.home {
    text-align: center;
    background-color: rgb(0, 0, 0, 0.65);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.title_text {
    font-size: 40px;
    font-weight: 600;
    font-family: 'Hiragino Sans GB';
    border-bottom: 2px solid #fff;
    padding-bottom: 20px;
    color: white;
}

.text_dec {
    font-size: 20px;
    font-weight: 60;
    font-family: 'Hiragino Sans GB';
    color: #f9f7f7;
    margin-top: 40px;
}

.download {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 15px 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border: #fff 2px solid;
    background-color: rgb(0, 0, 0, 0);
}

.download:hover {
    background-color: rgb(0, 0, 0, 0.5);
    border: gray 2px solid;
}

.download:focus{
    background-color: rgb(0, 0, 0, 0);
    border: white 2px solid;
}

.download:active {
    background-color: white;
    color: black;
}



.text-download {
    font-size: 16px;
    font-weight: 60;
    font-family: 'Hiragino Sans GB';
    color: #fff;
}

.icon-download {
    margin-left: 20px;
}
</style>