import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import isMobile from './assets/scripts/Device.js'

import ArcoVue from '@arco-design/web-vue';

import '@arco-design/web-vue/dist/arco.css';
// 额外引入图标库
import ArcoVueIcon from '@arco-design/web-vue/es/icon';

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$isMobile = isMobile
import loadI18n from './plugin/i18n';
loadI18n(app);
app.use(store).use(router).use(ElementPlus).use(ArcoVue,{
  // 用于改变使用组件时的前缀名称
  componentPrefix: 'arco'
}).use(ArcoVueIcon).mount('#app')
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
   // console.log("beforeEach:",from,to)
    next()
  })