import { setting } from '@/config/setting';
const { langKey, themeKey } = setting;
import Cookies from 'js-cookie';

export function getLanguage() {
  return Cookies.get(langKey);
}

export function setLanguage(lang) {
  return Cookies.set(langKey, lang);
}

export function getSettings() {
  const settings = Cookies.get(themeKey);
  return settings ? JSON.parse(settings) : null;
}

export function setSettings(theme) {
  return Cookies.set(themeKey, JSON.stringify(theme));
}

export function setToken(token){
  return Cookies.set('token',token,{ expires: 3})
}
export function getToken(){
  return Cookies.get('token')
}

export function removeToken(){
  return Cookies.remove('token')
}

