<template>
    <div class="nav">
        <div class="logo">
            <img src="@/assets/logo.png" alt="logo" />
            <b v-if="!isMobile">杭州土哩土气智能科技有限公司</b>
        </div>
        <el-menu mode="horizontal" :ellipsis="isMobile" background-color="rgb(0, 0, 0, 0.4)" text-color="#fff"
            active-text-color="#797979" @select="selectmenu" :default-active="default_active">
            <div class="flex-grow"></div>
            <el-menu-item index="1">主页</el-menu-item>
            <el-menu-item index="2">特点</el-menu-item>
            <el-menu-item index="3">截图</el-menu-item>
            <el-menu-item index="4">服务</el-menu-item>
        </el-menu>
    </div>
</template>




<script>
import { getCurrentInstance, computed, ref } from 'vue';
export default {
    name: 'HeadNav',
    setup(props, ctx) {
        let { proxy } = getCurrentInstance();
        let default_active = ref('1')
        console.log('是否是手机端1：', proxy.$isMobile());
        let isMobile = computed(() => proxy.$isMobile());
        let selectmenu = (index) => {
            //onsole.log('点击菜单index', index);
            ctx.emit('selectmenu', index);
        };
        let menuAutoSelect=(index)=>{
            default_active.value=index+""
        }
        return {
            isMobile,
            selectmenu,
            default_active,
            menuAutoSelect
        };
    }
}

</script>




<style lang="scss" scoped>
.nav {
    display: flex;
    padding: 0;
    height: 60px;
    flex-direction: row;
}

.el-menu {
    padding-left: 0%;
    justify-content: flex-end;
    padding-right: 20px;
    flex: 1;
}

.logo>img {
    height: 40px;
    width: 40px;
}

.logo>b {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-left: 20px;
    font-family: 'Hiragino Sans GB';
}


.el-menu {
    border: none;
    flex: 1;
}


.el-menu-item:active {
    border-bottom: #fff 1px solid;
}

.flex-grow {
    flex: 1;
}

.logo {
    align-items: center;
    display: flex;
    padding-left: 5%;
    background-color: rgb(0, 0, 0, 0.4);
}
</style>