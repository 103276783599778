<template>
    <div>
        <el-image :src="require('@/assets/' + imageurl1)" fit="cover" ref="imageRef1" style="opacity: 1;" />
        <el-image :src="require('@/assets/' + imageurl2)" fit="cover" ref="imageRef2" style="opacity: 0;" />
    </div>
</template>



<script>
import { ref } from 'vue';
export default {
    name: 'SlidImage',
    setup() {
        const timeCount = 500
        let imageurl1 = ref('bg-slider/bg-1.jpg');
        let imageurl2 = ref('bg-slider/bg-2.jpg');
        let imageRef1 = ref(null)
        let imageRef2 = ref(null)
        var startTime = 0
        var imageIndex = 2
        var imageFlag = 1
        let changeImage = () => {
            var ct_time = new Date().getTime();
            if (ct_time - startTime > timeCount) {
                if (imageIndex < 3) {
                    imageIndex++
                } else {
                    imageIndex = 1
                }
                if (imageFlag == 2) {
                    imageurl1.value = 'bg-slider/bg-' + imageIndex + '.jpg'
                } else {
                    imageurl2.value = 'bg-slider/bg-' + imageIndex + '.jpg'
                }
                return
            }
            let progress = (ct_time - startTime) / timeCount
            if (imageFlag == 2) {
                imageRef2.value.$el.style.opacity = progress
                imageRef1.value.$el.style.opacity = 1 - progress
            } else {
                imageRef1.value.$el.style.opacity = progress
                imageRef2.value.$el.style.opacity = 1 - progress
            }
            requestAnimationFrame(changeImage)
        }
        setInterval(() => {
            startTime = new Date().getTime();
            if (imageFlag == 1) {
                imageFlag = 2
            } else {
                imageFlag = 1
            }
            requestAnimationFrame(changeImage)
        }, 10000);
        return {
            imageurl1,
            imageurl2,
            imageRef1,
            imageRef2
        };
    }
};

</script>



<style lang="scss" scoped>
.el-image {
    position: fixed;
    height: 100vh;
    width: 100vw;
}
</style>