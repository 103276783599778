<template>
    <div class="feature">
        <el-space :size="10" wrap class="el-space">
            <el-card :body-style="{ padding: '0px', width: '350px', height: '60vh' }" v-for="(item, index) in list" :key="index">
                <el-image :src="require('@/assets/' + item.image)" class="el-image"/>
                <h2>{{item.title}}</h2>
                <p>{{item.content}}</p>
            </el-card>
        </el-space>
    </div>
</template>

<script>
import { ref,computed } from 'vue';
import { useStore } from 'vuex';
export default {
    name: 'Feature',
    setup() {
        let store = useStore();
        let list = computed(() => store.state.featurelist);
        return {
           list
        };
    }
}
</script>

<style lang="scss" scoped>
.feature {
    display: flex;
    min-height: 100vh;
    background-color: #f9f9f9;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    width: 100%;
}



h2 {
    margin-top: 20px;
    margin-left: auto;
    text-align: center;
}

p {
    margin: 10px;
    text-align: justify;
    color: #808080;
}

.el-space{
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.el-image{
    width: 100%;
    height: 50%;
}
</style>