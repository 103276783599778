<template>
    <div class="contact">
        <a href="https://beian.miit.gov.cn/" style='color:white'>ICP 备案:浙ICP备2021034677号</a>
    </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>

.contact{
    display: flex;
    justify-content: center;
    background-color: #333333;
    padding-bottom: 60px;
    width: 100%;
}
.contact>img{
    height: 40px;
    width: 40px;
    margin-right: 20px;
}

.contact>p{
    color: #fff;
    font-size: 15px;
    font-weight: 100;
    font-family: 'Hiragino Sans GB';
}

.contact>link{
    color: #fff;
}

</style>