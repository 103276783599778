<template>
    <div id="main">
        <SlidImage class="slide"></SlidImage>
        <MainScroller class="scrollbar" ref="scrollRef" @menuposition="menuposition"></MainScroller>
        <HeadNav class="nav" @selectmenu="selectmenu" ref="navRef"></HeadNav>
    </div>
</template>



<script>
import HeadNav from '@/components/homepage/HeadNav.vue';
import SlidImage from '@/components/homepage/SlideImage.vue';
import MainScroller from '@/components/homepage/MainScroller.vue';
import { defineComponent,getCurrentInstance,computed,ref} from 'vue';
import ScrollTest from '@/components/homepage/ScrollTest.vue';
export default defineComponent({
    name: 'CKMain',
    components: {
    HeadNav,
    SlidImage,
    MainScroller,
    ScrollTest
},
    mounted() {
        
    },
    setup() {
        let scrollRef=ref(null)
        let navRef=ref(null)
        let selectmenu=(index)=>{
            console.log('selectmenu~~~',index);
            scrollRef.value.autoScroll(index)
        }
        let menuposition=(index)=>{
            console.log('menuposition~~~',index);
            navRef.value.menuAutoSelect(index)

        }
    
        return {
            selectmenu,
            scrollRef,
            navRef,
            menuposition
        };
    }
});

</script>

<style scoped lang="scss">
.nav {
    position: fixed;
    z-index: 3;
    top: 0%;
    width: 100%;
}

.slide {
    position: fixed;
    z-index: 1;
    top: 0%;
}

.scrollbar {
    position: relative;
    top: 0;
    z-index: 2;
    width: 100%;
}

p{
    position: relative;
    top: 0;
    z-index: 2;
    width: 100%; 
    background-color: red; 
    font-size: 40px;
    z-index: 4;
    color: white;
}
</style>