<template>
    <div class="screen-shoot">
        <el-text class="title">软件截图</el-text>
        <el-space :size="10" wrap class="el-space">
            <el-card :body-style="{ padding: '0px', width: '350px' }" v-for="(item, index) in picList" :key="index">
                <el-image :src="require('@/assets/' + item)" class="el-image"/>
            </el-card>
        </el-space>
    </div>
</template>

<script>

import { ref,computed } from 'vue';
import{useStore} from 'vuex';
export default {
    setup () {
        let store=useStore();
        let picList=computed(()=>store.state.screenshootlist);
        return {
            picList  
        }
    }
}
</script>

<style lang="scss" scoped>

.screen-shoot{
    background-color: #333333;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    font-size: 35px;
    color: white;
    margin-top: 60px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding-top: 20px;
    padding-bottom: 20px;
}
.el-space{
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}


</style>