import axios from "axios";

import { ElMessage } from 'element-plus';
 
const initAxios = axios.create({
  timeout: 100000//数据响应过期时间
})
 
//请求拦截器
initAxios.interceptors.request.use((config) => {
  //在发送之前做点什么
  console.log("请求之前。。。")
  return config
}, (error) => {
  //对请求错误做点什么
  console.log("请求错误："+error)
  return error
})
 
//响应拦截器
initAxios.interceptors.response.use((response) => {
  if (response.status === 200 ) {
    // console.log("请求成功:"+JSON.stringify(response.data))
    if(response.data.code === 0){
      return response.data.data 
    }else{
      ElMessage.error(response.data.msg)
    }
  } else {
    console.log("请求失败："+response.statusText)
    return {resultCode: -1}
  }
})
//导出
export default () => initAxios