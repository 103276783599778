<template>
  <router-view/>
</template>


<script>
import { computed } from 'vue';
export default {
  name: 'App',
  methods: {
    
  },
  setup() {
    
  }
};

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
}
</style>
