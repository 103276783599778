//引入封装好的 service
import service from "@/service/service";

import { getToken } from "@/utils/cookies";
 
// //声明一个基础接口变量
// let baseURL;
 
// //配置开发环境
// if (process.env.NODE_ENV === 'development') {
//     baseURL = 'http://www.****.com:8000/';
// }
 
// // 配置生产环境
// if (process.env.NODE_ENV === 'production') {
//   baseURL = 'http://www.*****.com:8001/';
// }
 
//设置请求头（如果请求头统一的话可以在axios文件设置，则无须从这里传过去）
const header = {
  'Accept': 'application/json;charset=UTF-8',
  'token': getToken(),
  'Access-Control-Allow-Origin' : '*'
}
 
//根据自身需求
let _service={
    getVersion() {
        const url = "/api/app/getVersion";
        return service.get(url, {}, header);
    },
    login(ph,pw){
        const url = "/api/admin/login";
        return service.post(url, {phone:ph,pwd:pw}, header);
    },
    getWanNianLi(){
        const url = "/api/admin/wannianli";
        return service.get(url, {}, header);
    },
    getUserStatistics(){
        const url = "/api/admin/userstatistics";
        return service.get(url, {}, header);
    },
    getUserList(page,size){
        const url = "/api/admin/userList";
        return service.get(url, {page:page,pagesize:size}, header);
    },
    searchUser(key){
        const url = "/api/admin/searchUser";
        return service.get(url, {key:key}, header);
    },
    getEventList(page,size){
        const url = "/api/admin/event/getEventList";
        return service.get(url, {page:page,size:size}, header);
    },
    getEventListByUserId(userId){
        const url = "/api/admin/event/getEventListByUserId";
        return service.get(url, {userId:userId}, header);
    },
    getAdviceList(page,size){
        const url = "/api/admin/advice/find";
        return service.get(url, {page:page,size:size}, header);
    },
    getRegisterCountByDay(startDate,endDate){
        const url = "/api/admin/userregistercount";
        return service.get(url, {startDate:startDate,endDate:endDate}, header);
    },
    getRegisterCountByYear(year){
        const url = "/api/admin/userregistercountbyYear";
        return service.get(url, {year:year}, header);
    },
    getAppVersionData(){
        const url = "/api/admin/appversioncount";
        return service.get(url, {}, header);
    },
    getAppBrandData(){
        const url = "/api/admin/appbrandcount";
        return service.get(url, {}, header);
    }   

}
 
 
//导出
export default _service