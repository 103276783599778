<template>
    <el-scrollbar ref="scrollbar" always @scroll="scroll" noresize>
        <HomePage ref="home_element"></HomePage>
        <Feature ref="feature_element"></Feature>
        <screen-shoot ref="screen_element"></screen-shoot>
        <Service ref="service_element"></Service>
        <Contact></Contact>
    </el-scrollbar>
</template>



<script>
import HomePage from '@/components/homepage/HomePage.vue';
import Feature from './Feature.vue';
import Service from './Service.vue';
import ScreenShoot from './ScreenShoot.vue';
import Contact from './Contact.vue';
import { ElScrollbar } from 'element-plus';
import { ref, computed } from 'vue';
const timeCount = 500;
var startTime = 0;
export default {
    name: 'MainScroller',
    components: {
        HomePage,
        Feature,
        Service,
        ScreenShoot,
        Contact
    },
    props: {

    },
    mounted() {
        window.onresize = () => {
            // console.log('窗口大小改变');
        };
    },
    setup(props, ctx) {
        let scrollbar = ref(null);
        const home_element = ref()
        const feature_element = ref()
        const screen_element = ref()
        const service_element = ref()
        const firstHeight = computed(() => home_element.value.$el.offsetHeight)
        const secondHeight = computed(() => feature_element.value.$el.offsetHeight + home_element.value.$el.offsetHeight)
        const thirdHeight = computed(() => screen_element.value.$el.offsetHeight + feature_element.value.$el.offsetHeight + home_element.value.$el.offsetHeight)
        var currentScrollTop = 0;
        var endDistance = 0;
        let scroll = (e) => {
            // console.log('滚动条滚动', e.scrollTop, firstHeight.value, secondHeight.value, thirdHeight.value);
            currentScrollTop = e.scrollTop;
            //console.log('滚动条滚动', e);
            if (e.scrollTop < firstHeight.value) {
                // console.log('滚动到了首页');
                ctx.emit('menuposition', 1)
            } else if (firstHeight.value <= e.scrollTop && e.scrollTop < secondHeight.value) {
                // console.log('滚动到了特性');
                ctx.emit('menuposition', 2)
            } else if (secondHeight.value <= e.scrollTop && e.scrollTop < thirdHeight.value) {
                // console.log('滚动到了截图');
                ctx.emit('menuposition', 3)
            } else if (e.scrollTop >= thirdHeight.value) {
                // console.log('滚动到了服务');
                ctx.emit('menuposition', 4)
            }
        };

        let autoScroll = (index) => {
            //console.log('autoScroll~~~', index, scrollbar.value);
            if (index == 1) {
                endDistance = 0;
            } else if (index == 2) {
                endDistance = firstHeight.value;
            } else if (index == 3) {
                endDistance = secondHeight.value;
            } else if (index == 4) {
                endDistance = thirdHeight.value;
            }
            startTime = new Date().getTime();
            window.requestAnimationFrame(
                smoothScroll
            );
        }


        let smoothScroll = (t) => {
            let ct = new Date().getTime();
            if ((ct - startTime) > timeCount) {
                scrollbar.value.setScrollTop(endDistance)
                return;
            }
            let dis = currentScrollTop + (endDistance - currentScrollTop) / timeCount * (ct - startTime);
            scrollbar.value.setScrollTop(dis)
            window.requestAnimationFrame(
                smoothScroll
            );
        }


        return {
            scroll,
            scrollbar,
            home_element,
            feature_element,
            screen_element,
            service_element,
            autoScroll
        };
    }
}
</script>


<style lang="scss" scoped>
.el-scrollbar {
    height: 100vh;
}
</style>